export const MetaData = [
    {
        id: 1,
        path: '/',
        title: "VB Dace | Best Chef's Design Cookware Products Online India",
        desp: "Buy Online VB DACE premium cookware designed after carefully researching and curating each product by Chef Venkatesh Bhatt."
    },
    {
        id: 2,
        path: '/category/kitchenware',
        title: "VB Dace | Buy Premium Kitchenware Sets, Products Online India",
        desp: "VB DACE premium kitchenware designed by Chef Venkatesh Bhatt made with premium materials for a long life! Shop online now."
    },
    {
        id: 3,
        path: '/category/tableware',
        title: "VB Dace | Buy Premium Tableware  Sets, Products Online India",
        desp: "Shop Online for VB DACE premium tableware and kitchenware designed after carefully researching and curating each product by Chef Venkatesh Bhatt."
    },
    {
        id: 4,
        path: '/static/about',
        title: "About Us | VB Dace",
        desp: "VB-DACE | Reimagines the way you cook. Designed and curated by Chef Venkatesh Bhat, after careful research. Please read through our About Us for more information."
    },
    {
        id: 5,
        path: '/static/contact',
        title: "Contact Us | VB Dace",
        desp: "VB DACE premium cookware and tableware is designed by Chef Venkatesh Bhatt. Contact us for more enquiries regarding our products."
    },
    {
        id: 6,
        path: '/static/shipping',
        title: "Shipping | VB Dace",
        desp: "All orders are shipped with an invoice from VB Dace Appliances Private Limited. Please read through our Shipping for more information"
    },
    {
        id: 7,
        path: '/static/return-policy',
        title: "Return Policy | VB Dace",
        desp: "At VB Dace, no order is complete until you are fully satisfied.  Please read through our Return Policy for more information"
    },
    {
        id: 8,
        path: '/static/terms-use',
        title: "Terms and Conditions | VB Dace",
        desp: "Please read through our Terms and Conditions for more information."
    },
    {
        id: 9,
        path: '/static/privacy-policy',
        title: "Privacy Policy | VB Dace",
        desp: "VB DACE premium cookware is designed by Chef Venkatesh Bhatt. Please read through our privacy policy for more information"
    },
    {
        id: 10,
        path: '/static/cookie-policy',
        title: "Cookie Policy | VB Dace",
        desp: "Please read through our cookie policy for more information"
    },
    {
        id: 11,
        path: '/category/allproducts',
        title: "All Products | VB Dace",
        desp: "Your one-stop destination for high-quality cookware, bakeware, utensils, and more"
    },
]