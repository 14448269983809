import React, { Component } from "react";
import "../assets/scss/layouts/AuthLayout.scss";

export class AuthLayout extends Component {
  render() {
    return (
      <div className="login-wrapper">
        <div className="d-flex w-md-100 h-100 left-area">
          {this.props.children}
        </div>
      </div>
    );
  }
}
