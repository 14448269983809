// import React from "react";
// import ReactDOM from "react-dom";
// import { Routes } from "./routes/index";
// import * as serviceWorker from "./serviceWorker";
// import { Provider } from "react-redux";
// import { store } from "redux/store";
// import "./assets/scss/index.scss";
// // import "react-notifications/lib/notifications.css";
// ReactDOM.render(
//   <Provider store={store}>
//     <Routes />
//   </Provider>,
//   document.getElementById("root")
// );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();


import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import { Routes } from "./routes";
import * as serviceWorker from './serviceWorker';

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import configureStore from "./redux/store/store";
import "react-notifications/lib/notifications.css";
import "./assets/scss/index.scss";
import 'react-phone-input-2/lib/style.css'

let { store, persistor } = configureStore();

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();